import { Box, Button, Checkbox, FormControl, FormLabel, Heading, HStack, IconButton, Input, InputGroup, InputLeftElement, Select, Stack, Table, TableContainer, Tbody, Td, Text, Textarea, Th, Thead, Tr, useDisclosure, VStack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
 } from '@chakra-ui/react'
import React, { useState } from 'react'
import { BiPhone } from 'react-icons/bi';
import { FaFileSignature, FaPlus, FaSquarePlus } from "react-icons/fa6";
import { MdDelete, MdEmail, MdOutlineSummarize, MdPhone } from 'react-icons/md';
import { FiCornerLeftUp, FiCornerRightUp } from "react-icons/fi";
import { GrNotes } from 'react-icons/gr';
import { TiAttachmentOutline } from 'react-icons/ti';
import { RxCross2 } from "react-icons/rx";
// import './style.css'
import { Country, State, City } from 'country-state-city';
// import img2 from '../../../img/invoice-img/invoiceTempTwo.PNG'
import img2 from '../../../img/invoice-img/1.png'
import img3 from '../../../img/invoice-img/3.png'
import img4 from '../../../img/invoice-img/4.png'
import img5 from '../../../img/invoice-img/5.png'
import img6 from '../../../img/invoice-img/6.png'
import img7 from '../../../img/invoice-img/7.png'
import img8 from '../../../img/invoice-img/8.png'
import img9 from '../../../img/invoice-img/9.png'
import img10 from '../../../img/invoice-img/10.png'
import img11 from '../../../img/invoice-img/11.png'
import img12 from '../../../img/invoice-img/12.png'
import img13 from '../../../img/invoice-img/13.png'
import InvoiceTemplate4 from '../../InvoiceTemplates/Template4/InvoiceTemplate4';
import InvoiceTemplate5 from '../../InvoiceTemplates/Template5/InvoiceTemplate5';
import InvoiceTemplate6 from '../../InvoiceTemplates/Template6/InvoiceTemplate6';
import InvoiceTemplate7 from '../../InvoiceTemplates/Template7/InvoiceTemplate7';
import InvoiceTemplate8 from '../../InvoiceTemplates/Template8/InvoiceTemplate8';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick'
// import InvoiceTemplateOne from '../../InvoiceTemplates/InvoiceTemplateOne';
import QuotationTemplate from './QuotationTemplate';
import { useSelector } from 'react-redux';

const QuotationGenerator = () => {
    const {isOpen, onOpen, onClose}=useDisclosure();

    const { user} = useSelector( state => state.userContainer );

    const [taxMode, setTaxMode] = useState('CGST_SGST');
    const [templateHide,SetTemplateHide]=useState(false)
    const [selectTemplate,SetSelectTemplate]=useState(0)

    const [quotationItem,SetQuotationItem]=useState([]) 
    const [totalSgstAmount,SetTotalSgstAmount]=useState(0)
    const [totalCgstAmount,SetTotalCgstAmount]=useState(0)
    const [totalIgstAmount,SetTotalIgstAmount]=useState(0)
    const [totalAmountWithTax,SetTotalAmountWithTax]=useState(0)
    const [totalAmount,SetTotalAmount]=useState(0)

    //Your company Details
    const [CompanyName,SetCompanyName]=useState('')
    const [YourName,SetYourName]=useState(user?.name)
    const [CompanyGSTTin,SetCompanyGSTTin]=useState('')
    const [CompanyCity,SetCompanyCity]=useState('')
    const [CompanyState,SetCompanyState]=useState('')
    const [CompanyCountry,SetCompanyCountry]=useState('')
    const [CompanyPhone,SetCompanyPhone]=useState(user?.phone)
    const [CompanyEmail,SetCompanyEmail]=useState(user?.email)
    const [CompanyAddress,SetCompanyAddress]=useState('')
    let testCompanyStatecode=CompanyState.split(',')[1]


    //Client company Details ClientClient
    const [ClientCompanyName,SetClientCompanyName]=useState('')
    const [ClientName,SetClientName]=useState('')
    const [ClientGSTTin,SetClientGSTTin]=useState('')
    const [ClientCity,SetClientCity]=useState('')
    const [ClientState,SetClientState]=useState('')
    const [ClientCountry,SetClientCountry]=useState('')
    const [ClientPhone,SetClientPhone]=useState('')
    const [ClientEmail,SetClientEmail]=useState('')
    const [ClientAddress,SetClientAddress]=useState('')
    let testClientStatecode=ClientState.split(',')[1]


    //logo and Quotation Details
    const [quotationLogo,SetQuotationLogo]=useState('')
    const [quotationName,SetQuotationName]=useState('')
    const [quotationNumber,SetQuotationNumber]=useState('')
    const [quotationDate,SetQuotationDate]=useState('')
    const [quotationDueDate,SetQuotationDueDate]=useState('')


 

    // biils item template formate
    const BillingTemplate={itemDes:'',qty:'',rate:'',igst:'',sgst:'',amountWithIgstTax:'',cgst:'',igstTotalAmount:'',sgstTotalAmount:'',cgstTotalAmount:'',amountWithTax:'',amount:''}
    const [BillData,SetBillData]=useState([BillingTemplate])

    const addFiled=()=>{
        SetBillData([...BillData,BillingTemplate])
    }
    const delFiled=(i)=>{
        
        const delval=[...BillData];
        delval.splice(i,1);
        SetBillData(delval)

        // for decrease amount With Tax  
        let testsubtotal=0;
        delval.forEach((item)=>{
             testsubtotal=item.amountWithTax+testsubtotal;
            SetTotalAmountWithTax(testsubtotal.toFixed(2))
        })

        // For decrease Total SGST of item
        let calculateSGST=0;
        delval.forEach((item)=>{
             calculateSGST=calculateSGST+Number(item.sgstTotalAmount);
            let newSGST= parseFloat(calculateSGST).toFixed(2)        
             SetTotalSgstAmount(newSGST)
             
        })

        // For decrease Total CGST of item
        let calculateCGST=0;
        delval.forEach((item)=>{
            calculateCGST=calculateCGST+Number(item.cgstTotalAmount); 
            let newCGST= parseFloat(calculateCGST).toFixed(2)                   
            SetTotalCgstAmount(newCGST)
        })

        // For decrease Total Amount of item 100+400=500 
        let testTotalAmount=0;
        delval.forEach((item)=>{
            testTotalAmount=testTotalAmount+item.amount;
            SetTotalAmount(testTotalAmount.toFixed(2))
        })
       
    }


    const handleChange=(e,i)=>{
        // SetItem(i)
        const {name,value}=e.target;
        let onchangeVal=[...BillData];
        onchangeVal[i][name]=value;
        SetBillData(onchangeVal)
        


        // For calcualte item Amount 2*300=600 and gst in itemTotal amount
        let TotalAmountVal=[...BillData]
        TotalAmountVal.forEach(item=>{
            const totalItemPrice=item.qty*item.rate;
            const itemSGST=Number(item.sgst);
            const itemCGST=Number(item.cgst);
            const itemIGST=Number(item.igst);
            const calculateItemAmountSGST=(itemSGST/100)*totalItemPrice;
            const calculateItemAmountCGST=(itemCGST/100)*totalItemPrice;
            const calculateItemAmountIGST=(itemIGST/100)*totalItemPrice;

            TotalAmountVal[i]['sgstTotalAmount']=parseFloat(calculateItemAmountSGST).toFixed(2)
            TotalAmountVal[i]['cgstTotalAmount']= parseFloat(calculateItemAmountCGST).toFixed(2)
            TotalAmountVal[i]['igstTotalAmount']= parseFloat(calculateItemAmountIGST).toFixed(2)
            // SetItemSgstAmount(calculateItemAmountSGST); //sgst % amount
            // SetItemCgstAmount(calculateItemAmountCGST); //cgst % amount
            // {taxMode === 'IGST' && <Text>Total IGST</Text>}
            // let floatTaxWithAmount=parseFloat(totalTaxWithAmount).toFixed(2)
            
            if(taxMode === 'IGST'){
                let totalIgstTaxWithAmount=calculateItemAmountIGST+totalItemPrice;
                TotalAmountVal[i]['amountWithTax']=totalIgstTaxWithAmount;
                
            }else{
                let totalTaxWithAmount=calculateItemAmountSGST+calculateItemAmountCGST+totalItemPrice;
                TotalAmountVal[i]['amountWithTax']=totalTaxWithAmount ;

            }
            TotalAmountVal[i]['amount']=totalItemPrice;
            // SetItemAmount(calculateItemAmount);
            // SetData(onchangeVal)
        })


        // For calculate Total SGST of item
        let calculateSGST=0;
        BillData.forEach((item)=>{
             calculateSGST=calculateSGST+Number(item.sgstTotalAmount);   
            let newSGST= parseFloat(calculateSGST).toFixed(2)        
             SetTotalSgstAmount(Number(newSGST))
        })


        // For calculate Total CGST of item
        let calculateCGST=0;
        BillData.forEach((item)=>{
            calculateCGST=calculateCGST+Number(item.cgstTotalAmount);  
            let newCGST= parseFloat(calculateCGST).toFixed(2)                   
            SetTotalCgstAmount(Number(newCGST))
        })

         // For calculate Total IGST of item
         let calculateIGST=0;
         BillData.forEach((item)=>{
             calculateIGST=calculateIGST+Number(item.igstTotalAmount);  
             let newIGST= parseFloat(calculateIGST).toFixed(2)                   
             SetTotalIgstAmount(Number(newIGST))
         })


        // For calcualte Total Amount with tax of item 100+400+%=500 totalAmount
        let testTotalAmountWithTax=0;
        BillData.forEach((item)=>{
            testTotalAmountWithTax=testTotalAmountWithTax+item.amountWithTax;
             SetTotalAmountWithTax(parseFloat(testTotalAmountWithTax).toFixed(2))
        })

        // For calcualte Total Amount of item 100+400=500  parseFloat(totalAmount).toFixed(2)
        let testTotalAmount=0;
        BillData.forEach((item)=>{
            testTotalAmount=testTotalAmount+item.amount;
            SetTotalAmount(testTotalAmount);
        })

        //product item array
        let itemArray=[];
        BillData.forEach((item)=>{
           let singalItem=item.itemDes;
           itemArray.push(singalItem)           
        })
        SetQuotationItem(itemArray)

    }
    const logoHandler=(e)=>{
    const file=e.target.files[0]
    const Reader=new FileReader()

    Reader.readAsDataURL(file)

    Reader.onload=()=>{
        SetQuotationLogo(Reader.result)
    }
    }

    const OpenQuotationTemplate=()=>{

        // if(!CompanyName || !YourName){

        // }
        onOpen()
        // if(selectTemplate>=1){
        //     SetTemplateHide(true)
        // }else{
        //     alert('please select Quotation template first')
        // }
   
    }

    const CheckStateIsSame=(clientState)=>{
        SetClientState(clientState);
        // Compare states and set tax mode
        setTaxMode(CompanyState === clientState ? 'CGST_SGST' : 'IGST');      
    }
    const images = [img7];
    // const images = [img1, img2,img7, img10, img5, img11, img6, , img8, img9, img3, img4, img12, img13];
    
    const settings = {
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          }
        ]
      };
      function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "block", background: "#792ee9" }}
            onClick={onClick}
          />
        );
      }    
      function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "block", background: "#792ee9", }}
            onClick={onClick}
          />
        );
      }

    return (
    <>
        {
            templateHide?null:(

        <>
            <Stack w={'100%'} p={'2rem 0'} alignItems={'center'} justifyContent={'center'}>
                <Heading w={['100%', '90%']} m={'auto'} mb={5} color={'rgba(255, 138, 180, 1)'} fontSize={['1rem', '2.3rem']} textAlign={'center'} children={'Create Quotation in Less than 2 Minutes'} />
            </Stack>
            <Box w={'90%'} p={'2rem 0'} m={'auto'} color={'white'} bgGradient={'linear(to-b, rgba(22, 18, 208, 0.84), rgba(196, 2, 188, 1))'} borderRadius={8}>
                {/* Top  */}
                <Stack
                    w={'95%'}
                    p={['.7rem', '1rem']}
                    m={'auto'}
                    alignItems={['flex-start', 'center']}
                    justifyContent={'space-between'}
                    direction={{ base: 'column', md: 'row' }}
                >
                    <VStack w={{ base: '100%', md: '50%' }} spacing={4}>
                        <HStack w={'100%'}>
                            <Text w={['100%', '30%']}>Quotation No*</Text>
                            <Input onChange={(e)=>{SetQuotationNumber(e.target.value)}} value={quotationNumber} color={'white'} _placeholder={{color:'white'}} placeholder={'quotation No - 110'} type='number' w={['100%', '40%']} size={['sm', 'md']} />
                        </HStack>
                        <HStack w={'100%'}>
                            <Text w={['100%', '30%']}>Quotation Date*</Text>
                            <Input onChange={(e)=>{SetQuotationDate(e.target.value)}} value={quotationDate} type='date' w={['100%', '40%']} size={['sm', 'md']} />
                        </HStack>
                        <HStack w={'100%'}>
                            <Text w={['100%', '30%']}>Due Date*</Text>
                            <Input onChange={(e)=>{SetQuotationDueDate(e.target.value)}} value={quotationDueDate} type='date' w={['100%', '40%']} size={['sm', 'md']} />
                        </HStack>


                    </VStack>

                    <VStack w={{ base: '100%', md: '50%' }} alignItems={['flex-start', 'flex-end']} spacing={4}>
                        <FormControl w={{ base: '100%', md: '40%' }}>
                            <FormLabel>Add Business Logo</FormLabel>
                            <Input cursor={'pointer'} color={'white'} type='file'  onChange={(e)=>{logoHandler(e)}} accept={'image/*'} size={['sm', 'md']} />
                        </FormControl>
                    </VStack>
                </Stack>



                {/* Quotation form */}
                <Stack w={'100%'} mb={10} spacing={3} alignItems={'center'} justifyContent={'center'} direction={{ base: 'column', md: 'row' }}>

                    {/* Your from details */}
                    <Box  mt={4} p={5}>
                        <Text mb={[2, 5]} fontSize={{ base: '1.2rem', md: '1.4rem' }} >Your Details </Text>
                        <VStack w={'100%'} bg={'rgba(255, 255, 255, 1)'} color={'rgba(151, 8, 204, 1)'} p={['1rem', '2rem']} borderRadius={8}>

                            <FormControl mt={4} >
                                <Input onChange={(e) => { SetYourName(e.target.value) }} value={YourName} size={['sm', 'md']} type='text' border={'none'} borderBottom={'1px solid rgba(151, 8, 204, 1)'} _placeholder={{ color: 'rgba(151, 8, 204, 1)' }} placeholder='Your Name / Freelancer Name ' />
                            </FormControl>
                            <FormControl mt={4}>
                                <Input onChange={(e) => { SetCompanyName(e.target.value) }} value={CompanyName} type='text' size={['sm', 'md']} border={'none'} borderBottom={'1px solid rgba(151, 8, 204, 1)'} _placeholder={{ color: 'rgba(151, 8, 204, 1)' }} placeholder='Company Name' />
                            </FormControl>

                            <FormControl mt={4}>
                                <Select onChange={(e) => { SetCompanyCountry(e.target.value) }} value={CompanyCountry} size={['sm', 'md']} borderRadius={5} border={'1px solid rgba(151, 8, 204, 1)'}>
                                    <option style={{ color: 'black' }} value={Country.getCountryByCode('IN').name}>{Country.getCountryByCode('IN').name}</option>
                                </Select>
                            </FormControl>
                            <FormControl mt={4} border={'1px solid rgba(151, 8, 204, 1)'} borderRadius={5}>
                                <Select onChange={(e) => { SetCompanyState(e.target.value) }} value={CompanyState} placeholder='Select State' size={['sm', 'md']}>
                                    {
                                        State.getStatesOfCountry('IN').map((item, i) => (
                                            <option key={i} style={{ color: 'black' }} value={`${item.name},${item.isoCode}`}>{`${item.name}, ${item.isoCode}`} </option>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                            <HStack w={'100%'} >

                                <FormControl mt={4} borderBottom={'1px solid rgba(151, 8, 204, 1)'} borderRadius={5}>
                                    <Select onChange={(e) => { SetCompanyCity(e.target.value) }} value={CompanyCity} placeholder='Select City' size={['sm', 'md']}>
                                        {
                                            City.getCitiesOfState('IN', testCompanyStatecode).map((item, i) => (
                                                <option key={i} style={{ color: 'black' }} value={item.name}>{item.name}</option>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl mt={4}>
                                    <Input size={['sm', 'md']} border={'none'} borderBottom={'1px solid rgba(151, 8, 204, 1)'} _placeholder={{ color: 'rgba(151, 8, 204, 1)' }} type='text' placeholder='Postal Code/ Zip Code' />

                                </FormControl>
                            </HStack>


                            <HStack mt={4} w={['100%', '90%']} alignItems={'center'} justifyContent={'space-between'}>
                                <VStack w={'100%'}>
                                    <InputGroup size={['sm', 'md']}>
                                        <InputLeftElement pointerEvents='none'>
                                            <MdEmail />
                                        </InputLeftElement>
                                        <Input onChange={(e)=>{SetCompanyEmail(e.target.value)}} value={CompanyEmail} border={'none'} _placeholder={{ color: 'rgba(151, 8, 204, 1)' }} type='email' placeholder='Your Email' />
                                    </InputGroup>
                                    <InputGroup size={['sm', 'md']}>
                                        <InputLeftElement pointerEvents='none'>
                                            <FaPlus />
                                        </InputLeftElement>
                                        <Input onChange={(e)=>{SetCompanyGSTTin(e.target.value)}} value={CompanyGSTTin} border={'none'} _placeholder={{ color: 'rgba(151, 8, 204, 1)' }} type='text' placeholder='Your GST Tin-No' />
                                    </InputGroup>
                                </VStack>
                                <VStack w={'100%'}>
                                    <InputGroup size={['sm', 'md']}>
                                        <InputLeftElement pointerEvents='none'>
                                            <BiPhone />
                                        </InputLeftElement>
                                        <Input onChange={(e)=>{SetCompanyPhone(e.target.value)}} value={CompanyPhone}  border={'none'} _placeholder={{ color: 'rgba(151, 8, 204, 1)' }} type='tel' placeholder='Your Phone Number' />
                                    </InputGroup>
                                    <InputGroup size={['sm', 'md']}>
                                        <InputLeftElement pointerEvents='none'>
                                            <FaPlus />
                                        </InputLeftElement>
                                        <Input onChange={(e)=>{SetCompanyAddress(e.target.value)}} value={CompanyAddress}  border={'none'} _placeholder={{ color: 'rgba(151, 8, 204, 1)' }} type='text' placeholder='Your Company Address' />
                                    </InputGroup>
                                </VStack>
                            </HStack>

                        </VStack>
                    </Box>

                    {/* Client form details */}
                    <Box mt={5} p={5}>
                        <Text mb={[2, 5]} fontSize={{ base: '1.2rem', md: '1.4rem' }} >Client Details </Text>
                        <VStack w={'100%'} bg={'rgba(255, 255, 255, 1)'} color={'rgba(151, 8, 204, 1)'} p={['1rem', '2rem']} borderRadius={8}>ClientCompanyName

                            <FormControl mt={4} >
                                <Input onChange={(e) => { SetClientName(e.target.value) }} value={ClientName} type='text' size={['sm', 'md']} border={'none'} borderBottom={'1px solid rgba(151, 8, 204, 1)'} _placeholder={{ color: 'rgba(151, 8, 204, 1)' }} placeholder='Client Name ' />
                            </FormControl>
                            <FormControl mt={4}>
                                <Input onChange={(e) => { SetClientCompanyName(e.target.value) }} value={ClientCompanyName} size={['sm', 'md']} type='text' border={'none'} borderBottom={'1px solid rgba(151, 8, 204, 1)'} _placeholder={{ color: 'rgba(151, 8, 204, 1)' }} placeholder='Client Company Name' />
                            </FormControl>

                            <FormControl mt={4}>
                                <Select onChange={(e) => { SetClientCountry(e.target.value) }} value={ClientCountry} size={['sm', 'md']} borderRadius={5} border={'1px solid rgba(151, 8, 204, 1)'}>
                                    <option style={{ color: 'black' }} value={Country.getCountryByCode('IN').name}>{Country.getCountryByCode('IN').name}</option>
                                </Select>
                            </FormControl> 
                            <FormControl mt={4} border={'1px solid rgba(151, 8, 204, 1)'} borderRadius={5}>
                                <Select onChange={(e) => { CheckStateIsSame(e.target.value) }} value={ClientState} placeholder='Select State' size={['sm', 'md']}>
                                    {
                                        State.getStatesOfCountry('IN').map((item, i) => (
                                            <option key={i} style={{ color: 'black' }} value={`${item.name},${item.isoCode}`}>{`${item.name}, ${item.isoCode}`} </option>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                            <HStack w={'100%'}>

                                <FormControl mt={4} borderBottom={'1px solid rgba(151, 8, 204, 1)'} borderRadius={5}>
                                    <Select onChange={(e) => { SetClientCity(e.target.value) }} value={ClientCity} placeholder='Select City' size={['sm', 'md']}>
                                        {
                                            City.getCitiesOfState('IN', testClientStatecode).map((item, i) => (
                                                <option key={i} style={{ color: 'black' }} value={item.name}>{item.name}</option>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl mt={4}>
                                    {/* <Select size={['sm', 'md']} border={'none'} borderBottom={'1px solid rgba(151, 8, 204, 1)'} _placeholder={{ color: 'rgba(151, 8, 204, 1)' }} type='text' placeholder='Postal Code/ Zip Code' /> */}
                                    <Input size={['sm', 'md']} border={'none'} borderBottom={'1px solid rgba(151, 8, 204, 1)'} _placeholder={{ color: 'rgba(151, 8, 204, 1)' }} type='text' placeholder='Postal Code/ Zip Code' />

                                </FormControl>
                            </HStack>

                            <HStack mt={4} w={['100%', '90%']} alignItems={'center'} justifyContent={'space-between'}>
                                <VStack>
                                    <InputGroup size={['sm', 'md']}>
                                        <InputLeftElement pointerEvents='none'>
                                            <MdEmail />
                                        </InputLeftElement>
                                        <Input onChange={(e)=>{SetClientEmail(e.target.value)}} value={ClientEmail}  border={'none'} _placeholder={{ color: 'rgba(151, 8, 204, 1)' }} type='email' placeholder='Client Email' />
                                    </InputGroup>
                                   <InputGroup size={['sm', 'md']}> 
                                        <InputLeftElement pointerEvents='none'>
                                            <FaPlus />
                                        </InputLeftElement>
                                        <Input onChange={(e)=>{SetClientGSTTin(e.target.value)}} value={ClientGSTTin}  border={'none'} _placeholder={{ color: 'rgba(151, 8, 204, 1)' }} type='text' placeholder='Client GST Tin-No' />
                                    </InputGroup>
                                </VStack>
                                <VStack>
                                    <InputGroup size={['sm', 'md']}>
                                        <InputLeftElement pointerEvents='none'>
                                            <BiPhone />
                                        </InputLeftElement>
                                        <Input onChange={(e)=>{SetClientPhone(e.target.value)}} value={ClientPhone} border={'none'} _placeholder={{ color: 'rgba(151, 8, 204, 1)' }} type='tel' placeholder='Client Phone Number' />
                                    </InputGroup>
                                    <InputGroup size={['sm', 'md']}>
                                        <InputLeftElement pointerEvents='none'>
                                            <FaPlus />
                                        </InputLeftElement>
                                        <Input onChange={(e)=>{SetClientAddress(e.target.value)}} value={ClientAddress} border={'none'} _placeholder={{ color: 'rgba(151, 8, 204, 1)' }} type='text' placeholder='Client Address ' />
                                    </InputGroup>
                                </VStack>
                            </HStack>

                        </VStack>
                    </Box>
                </Stack>


                {/* table of bill */}
                <TableContainer w={'95%'} m={'auto'} fontFamily={'Roboto'} fontSize={['xs', 'sm', 'md', 'lg']} className="scroll-container">
                    <Table overflowX={'scroll'} w={'100%'}>
                        <Thead bg='rgba(22, 18, 208, 0.84)' >
                            <Tr >
                                <Th color={'white'}>Item & Description</Th>
                                <Th color={'white'} >Quantity</Th>
                                <Th color={'white'} >Rate</Th>
                                <Th color={'white'} >Total</Th>
                                <Th color={'white'} ></Th>
                                <Th color={'white'} ></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                BillData.map((item, i) => (
                                    <Tr key={i} >

                                        <Td  w={'100%'}><Textarea _placeholder={{color:'white'}} color={'white'} onChange={(e) => handleChange(e, i)} name={'itemDes'} value={item.itemDes} rows={1} fontSize={'1.1rem'} textTransform={'capitalize'} w={['200px', '300px']} variant='flushed' type='text' placeholder='item name' /></Td>
                                        <Td><Input _placeholder={{color:'white'}} color={'white'} onChange={(e) => handleChange(e, i)} name={'qty'} value={item.qty} w={['70px', '100px']} variant='flushed' type='number' /></Td>
                                        <Td><Input _placeholder={{color:'white'}} color={'white'} onChange={(e) => handleChange(e, i)} name={'rate'} value={item.rate} w={['70px', '100px']} variant='flushed' type='number' /></Td>                       
                                        <Td><Input _placeholder={{color:'white'}} color={'white'} onChange={(e) => handleChange(e, i)} name={'amount'} value={item.amount} w={['70px', '100px']} variant='flushed' type='number' /></Td>
                                        <Td><RxCross2 color={'red'} onChange={(e) => handleChange(e, i)} onClick={() => delFiled(i)} cursor={'pointer'} /></Td>

                                    </Tr>
                                ))
                            }
                            <Button m={'1rem 0'} cursor={'pointer'} ml={'1vmax'} onClick={() => addFiled()} colorScheme={'linear(to-l,#FF0080, #7928CA )'} size={['sm', 'md']} children={'Add Item'} bg='rgba(22, 18, 208, 1)' />


                        </Tbody>
                    </Table>
                </TableContainer>

                {/* Total Amount */}
                <Stack w={'100%'} p={[3, 5]} direction={{ base: 'column', md: 'row' }}>

                    <VStack w={'50%'}></VStack>
                    <VStack w={{ base: '100%', md: '50%' }} spacing={5} m={'auto'}>

                        <HStack w={['100%', '80%']} fontSize={'1.2rem'} alignItems={'center'} justifyContent={'space-between'}>
                            <Text children={'Amount'} />
                            <Text children={totalAmount} />
                        </HStack>
              
                        <HStack w={['100%', '80%']} borderTop={'1px dashed white'} fontSize={'1.2rem'} fontWeight={'600'} alignItems={'center'} justifyContent={'space-between'}>
                            <Text children={'Total (INR)'} />
                            <Text children={totalAmountWithTax} />
                        </HStack>
                    </VStack>
                </Stack>

                {/* Add icons button */}
                <Stack
                    color={'white'}
                    w={'92%'}
                    m={'auto'}
                    mt={5}
                    spacing={5}
                    alignItems={'center'}
                    justifyContent={'center'}
                    direction={{ base: 'column', md: 'row' }}
                >
                    {[
                        { icon: <MdEmail />, placeholder: 'Terms & Conditions', type: 'text' },
                        { icon: <GrNotes />, placeholder: 'Add Notes', type: 'text' },
                        { icon: <TiAttachmentOutline />, placeholder: 'Add Attachments', type: 'text' },
                        { icon: <GrNotes />, placeholder: 'Add Additional Info', type: 'text' },
                        { icon: <MdPhone />, placeholder: 'Add Contact Details', type: 'tel' },
                    ].map((inputProps, index) => (
                        <InputGroup
                            key={index}
                            size={['sm', 'md']}
                            w={'100%'}
                            border={'1px dashed white'}
                        >
                            <InputLeftElement pointerEvents='none'>
                                {inputProps.icon}
                            </InputLeftElement>
                            <Input
                                border={'none'}
                                type={inputProps.type}
                                placeholder={inputProps.placeholder}
                                _placeholder={{ color: 'white' }}
                                aria-label={inputProps.placeholder}
                            />
                        </InputGroup>
                    ))}
                </Stack>


                {/* Advance options */}
                <VStack w={'92%'} m={'auto'} alignItems={'flex-start'} mt={8}>
                    <Heading fontSize={'1.3rem'} fontWeight={'400'} children={'Advance Options'} />
                    <Checkbox size={{ base: 'sm', md: 'md' }} >Hide place Of Supply/Country Of Supply</Checkbox>
                    <Checkbox size={{ base: 'sm', md: 'md' }} >Add Original mages in Line Items</Checkbox>
                    <Checkbox size={{ base: 'sm', md: 'md' }} >Show description in full width</Checkbox>
                </VStack>



            </Box>

            {/* <div className="template-slider">
              <div className="slider-container ">
                <Slider {...settings}>
                  {images.map((img, index) => (
                    <div style={{width: "100%",display:'flex',margin:'1vmax',justifyContent:'center',alignItems:'center'}} key={index}>
                      <img onClick={()=>SetSelectTemplate(index+1)} src={img} alt={`Quotation Template ${index + 1}`} style={{ width: "100%",padding:'1vmax',cursor:'pointer'}} />
                    </div>
                  ))}
                </Slider>
              </div>
            </div> */}
            <HStack m={'2vmax 0'} justifyContent={'center'} >
                <Button _hover={{bgGradient:'linear(to-l,#FF0080, #7928CA)'}} bgGradient='linear(to-l, #7928CA, #FF0080)' color={'white'} textAlign={'center'} size={'lg'} onClick={()=>OpenQuotationTemplate()}  children={'Preview'} />       
            </HStack>

        </>
            )
        }


 
        <Modal size={'full'} isOpen={isOpen} onClose={onClose}> 
        <ModalOverlay />
        <ModalContent>
            <ModalBody>                   
                <QuotationTemplate
                    BillData={BillData}
                    totalAmountWithTax={totalAmountWithTax}
                    totalAmount={totalAmount}
                    totalSgstAmount={totalSgstAmount}
                    totalCgstAmount={totalCgstAmount}
                    totalIgstAmount={totalIgstAmount}

                    taxMode={taxMode}
                    
                    quotationItem={quotationItem}
                    quotationLogo={quotationLogo}
                    quotationName={quotationName}
                    quotationNumber={quotationNumber}
                    quotationDate={quotationDate}
                    quotationDueDate={quotationDueDate}

                    CompanyName={CompanyName} 
                    YourName={YourName} 
                    CompanyGSTTin={CompanyGSTTin}
                    CompanyCity={CompanyCity}
                    CompanyState={CompanyState}
                    CompanyCountry={CompanyCountry}

                    CompanyPhone={CompanyPhone}                   
                    CompanyEmail={CompanyEmail}                   
                    CompanyAddress={CompanyAddress}  

                    ClientCompanyName={ClientCompanyName} 
                    ClientName={ClientName} 
                    ClientGSTTin={ClientGSTTin}
                    ClientCity={ClientCity}
                    ClientState={ClientState}
                    ClientCountry={ClientCountry}     

                    ClientPhone={ClientPhone}                   
                    ClientEmail={ClientEmail}                   
                    ClientAddress={ClientAddress}                   
                />                      
            </ModalBody>
        </ModalContent>
    </Modal>
   
    </>
    )
}

export default QuotationGenerator