import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CreateInvoice } from '../../../redux/actions/InvoiceActions';
import { Box, Button, Heading, HStack, Image, Kbd, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, VStack } from '@chakra-ui/react';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { CreateQuotation } from '../../../redux/actions/QuotationActions';

const QuotationTemplate = ({
    BillData, totalAmountWithTax, totalAmount, totalSgstAmount, totalCgstAmount, quotationItem, quotationLogo, totalIgstAmount, taxMode,
    quotationName, quotationNumber, quotationDate, quotationDueDate,
    CompanyName, YourName, CompanyGSTTin, CompanyCity, CompanyState, CompanyCountry,CompanyPhone,CompanyEmail,CompanyAddress,
    ClientCompanyName, ClientName, ClientGSTTin, ClientCity, ClientState, ClientCountry,ClientPhone,ClientEmail,ClientAddress,
}) => {
    const [hidePrintBtn, setHidePrintBtn] = useState(false)
  const { user} = useSelector( state => state.userContainer );

  const contentRef = useRef();
// const reactToPrintFn = useReactToPrint({ contentRef });

    const dispatch = useDispatch()
    const navigate = useNavigate()


      // Function to handle print action and store quotation
      const reactToPrintFn = useReactToPrint({
        contentRef,
        onBeforePrint: async () => {
        await dispatch(CreateQuotation(quotationNumber, totalAmount, quotationDate, quotationItem, ClientCompanyName))
            // alert('api for quotation')
            // console.log('onBeforePrint')
        },
        onAfterPrint: () => {
            navigate('/profile'); 
            // console.log('onAfterPrint')
        }
    });

    const QuotationDataHandler = async () => {
        setHidePrintBtn(true)

        await dispatch(CreateQuotation(quotationNumber, totalAmountWithTax, quotationDate, quotationItem, ClientCompanyName))
        window.print()
        navigate('/quotationgenerator')
    }

        // Block Ctrl + P or Cmd + P print shortcut
        useEffect(() => {
            const handleKeyDown = (event) => {
                if ((event.ctrlKey || event.metaKey) && event.key === 'p') {
                    event.preventDefault(); // Block the default print behavior
                    alert('Printing via Ctrl+P is disabled. Please use the Print button.');
                }
            };
    
            // Attach event listener to keydown event
            window.addEventListener('keydown', handleKeyDown);
    
            // Cleanup the event listener when component unmounts
            return () => {
                window.removeEventListener('keydown', handleKeyDown);
            };
        }, []);

    return (

        <>
        <Box p={'5px'} mt={'4vmax'} ml={'1vmax'} ref={contentRef}>

      
            <VStack w={'100%'}  spacing={0} >
                <Image src={quotationLogo} alt={'quotationLogo'} w={'20%'} />
                <Heading fontSize={'1.5rem'} children={`${CompanyName} `} />
                <Text fontSize={'1rem'}>{`Tin-No ${CompanyGSTTin} ,${CompanyAddress} , ${CompanyCity} , ${CompanyState} , ${CompanyCountry}`}</Text>
                <Text fontSize={'1rem'}>{` ${YourName} ,  ${CompanyEmail} ,  ${CompanyPhone} `}</Text>
            </VStack>
            <HStack w={'100%'} alignItems={'center'} justifyContent={'space-between'}>
                <VStack w={'50%'} alignItems={'flex-start'} spacing={1}>
                    <Heading fontSize={'1.5rem'}>Issue to: <span style={{ fontWeight: '500', fontSize: '1.2rem' }}>{ClientCompanyName}</span> </Heading>
                    <Text fontWeight={400} fontSize={'1.1rem'} >Name - <span>{ClientName}</span></Text>
                    <Text fontWeight={400} fontSize={'1.1rem'} >Email - <span>{ClientEmail}</span></Text>
                    <Text fontWeight={400} fontSize={'1.1rem'} >Phone - <span>{ClientPhone}</span></Text>
                    <Text>{`Tin-No ${ClientGSTTin} , ${ClientAddress} , ${ClientCity} , ${ClientState} , ${ClientCountry}`}</Text>
                </VStack>

                <VStack w={'50%'} alignItems={'flex-end'}>
                    <HStack>
                        <VStack alignItems={'flex-start'}>
                            <Text fontWeight={'600'} textTransform={'capitalize'}>quotation no:</Text>
                            <Text fontWeight={'600'} textTransform={'capitalize'}>date:</Text>
                            <Text fontWeight={'600'} textTransform={'capitalize'}>due date:</Text>


                        </VStack>
                        <VStack alignItems={'flex-end'}>
                            <Text fontWeight={'600'} textTransform={'capitalize'}>{quotationNumber}</Text>
                            <Text fontWeight={'600'} textTransform={'capitalize'}>{quotationDate}</Text>
                            <Text fontWeight={'600'} textTransform={'capitalize'}>{quotationDueDate}</Text>
                        </VStack>
                    </HStack>
                   

                </VStack>
            </HStack>


            <TableContainer>
                <Table>
                    <Thead>

                        <Tr >
                            <Th fontSize={'1.1rem'} style={{ backgroundColor: "#DCCEAC" }}>DESCRIPTION</Th>
                            <Th fontSize={'1.1rem'} style={{ backgroundColor: "#DCCEAC" }}> QTY</Th>
                            <Th fontSize={'1.1rem'} style={{ backgroundColor: "#DCCEAC" }}>PRICE</Th>
                            <Th fontSize={'1.1rem'} style={{ backgroundColor: "#DCCEAC" }}>TOTAL</Th>
                        </Tr>


                    </Thead>
                    <Tbody>
                        {

                            BillData && BillData.map((Item, key) => (
                                <Tr key={key} >
                                    <Td style={{ fontWeight: "400", textTransform: 'capitalize' }}>{Item.itemDes}</Td>
                                    <Td style={{ fontWeight: "400" }}>{Item.qty}</Td>
                                    <Td style={{ fontWeight: "400" }}>{Item.rate}</Td>
                                    <Td style={{ fontWeight: "400" }}>{Item.amount}</Td>

                                </Tr>
                            ))
                        }
                    </Tbody>
                </Table>
            </TableContainer>

            <Stack>
       
                <VStack w={'96%'} alignItems={'flex-end'} >
                    <HStack  >
                        <Text style={{ fontSize: "1.2rem", fontWeight: "700" }}>
                            Total :
                        </Text>
                        <p style={{ fontSize: "1.2rem", fontWeight: "700" }}>{`${parseFloat(totalAmount).toFixed(2)}`}</p>
                    </HStack>
                    {/* <HStack >
                        <VStack>
                            {taxMode === 'CGST_SGST' && <p style={{ marginRight: "10px", fontWeight: '700' }} isNumeric>SGST : </p>}
                            {taxMode === 'CGST_SGST' && <p style={{ marginRight: "10px", fontWeight: '700' }} isNumeric>CGST : </p>}
                        </VStack>
                        <VStack>
                            {taxMode === 'CGST_SGST' && <p style={{ marginRight: "10px" }} isNumeric>{` ${totalSgstAmount}`}</p>}
                            {taxMode === 'CGST_SGST' && <p style={{ marginRight: "10px" }} isNumeric>{` ${totalCgstAmount}`}</p>}
                        </VStack>
                    </HStack> */}

                    {/* <HStack>
                        {taxMode === 'IGST' && <p style={{ marginRight: "10px", fontWeight: '700' }} isNumeric>IGST : </p>}
                        {taxMode === 'IGST' && <p style={{ marginRight: "10px" }} isNumeric>{` ${totalIgstAmount}`}</p>}
                    </HStack> */}


                    {/* <p>{totalSgstAmount+totalCgstAmount}</p> */}

                </VStack>
            </Stack>

            {/* <HStack w={'96%'} alignItems={'flex-end'} justifyContent={'flex-end'} >
                <p style={{ marginRight: "10px", fontWeight: '700' }}>Total:</p>
                <p style={{ marginRight: "10px" }}>{`${parseFloat(totalAmountWithTax).toFixed(2)}`}</p>
            </HStack> */}

            <HStack mt={3} w={'100%'} alignItems={'center'} justifyContent={'space-between'}>
                <VStack >
                    <h4>Company signature</h4>

                </VStack>

                <VStack >
                    <h4>Customer signature</h4>
                </VStack>
            </HStack>
            {/* {
                hidePrintBtn ? null : <Button children={'print'} variant={'solid'} colorScheme={"whatsapp"} onClick={() => InvoiceDataHandler()} />
            } */}
        </Box>
        {/* <ReactToPrint
            trigger={() => <Button variant={'solid'} colorScheme={'whatsapp'}>Print Invoice</Button>}
            content={() => componentRef.current}
        /> */}
         {/* <button onClick={reactToPrintFn}>Print</button> */}
         {/* <VStack w={'100%'} justifyContent={'center'} alignItems={'center'} > */}
            <Button children={'Print'} variant={'solid'} colorScheme={"whatsapp"} onClick={reactToPrintFn} />
            {/* <Text color={'red'} fontSize={'2vmax'}>
                <Kbd color={'black'}>ctrl</Kbd> + <Kbd color={'black'}>P</Kbd>
            </Text> */}
         {/* </VStack> */}
        </>
    )
}

export default QuotationTemplate