import React, { useState } from 'react'
import './InvoiceTemplate8.css'

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CreateInvoice } from '../../../redux/actions/InvoiceActions';
import { Button, HStack, Table, TableContainer, Tbody, Td, Th, Thead, Tr, VStack } from '@chakra-ui/react';


const InvoiceTemplate8 = ({
    BillData, totalAmountWithTax, totalAmount, totalSgstAmount, totalCgstAmount, invoiceItem, invoiceLogo, totalIgstAmount, taxMode,
    invoiceName, invoiceNumber, invoiceDate, invoiceDueDate,
    CompanyName, YourName, CompanyGSTTin, CompanyCity, CompanyState, CompanyCountry,
    ClientCompanyName, ClientName, ClientGSTTin, ClientCity, ClientState, ClientCountry
}) => {
    const [hidePrintBtn, setHidePrintBtn] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { user } = useSelector(state => state.userContainer);


    const InvoiceDataHandler = async () => {
        setHidePrintBtn(true)

        await dispatch(CreateInvoice(invoiceNumber, totalAmountWithTax, invoiceDate, invoiceItem, ClientCompanyName))
        window.print()
        navigate('/profile')
    }

    return (
        <>
            <div className="gigSection">
                <div className="gigYellowClr">
                    <div className="bgyellow"></div>
                    <div className="gigCaption">
                        {/* <img src={invoiceLogo} alt="invoiceLogo" /> */}
                        <h3>{CompanyName}</h3>
                        <h3>{YourName}</h3>
                        <li>{`GST-Tin${CompanyGSTTin} ${CompanyCity} ${CompanyState} ${CompanyCountry}`}</li>
                        <li>123 456 789</li>
                    </div>
                </div>


                <div className="yellowInvoice">
                    invoice
                </div>
            </div>

            <HStack w={'100%'} alignItems={'center'} justifyContent={'space-between'}>
                <VStack alignItems={'flex-start'}>
                    <h3>bill to:</h3>
                    <li>{ClientName}</li>
                    <li>{`GST-Tin${ClientGSTTin} ${ClientCity} ${ClientState} ${ClientCountry}`}</li>
                    <li>123 456 789</li>
                </VStack>
                <VStack alignItems={'flex-start'}>
                    <HStack>
                        <VStack alignItems={'flex-start'}>
                            <li>Invoice number:</li>
                            <li>Date:</li>
                            <li>Due date:</li>
                        </VStack>
                        <VStack alignItems={'flex-end'}>
                            <li>{invoiceNumber}</li>
                            <li>{invoiceDate}</li>
                            <li>{invoiceDueDate}</li>
                        </VStack>
                    </HStack>

                </VStack>
            </HStack>

            <TableContainer>
                <Table responsive="md">
                    <Thead>
                        <Tr >
                            <Th style={{ borderBottom: "3px solid black" }}>DESCRIPTION</Th>
                            <Th style={{ borderBottom: "3px solid black" }}> QTY</Th>
                            <Th style={{ borderBottom: "3px solid black" }}>PRICE</Th>
                            <Th style={{ borderBottom: "3px solid black" }}>TOTAL</Th>
                        </Tr>

                    </Thead>
                    <Tbody>
                        {

                            BillData && BillData.map((Item, key) => (
                                <Tr key={key}  >
                                    <Td style={{ fontWeight: "400", borderBottom: "3px solid black", }}>{Item.itemDes}</Td>
                                    <Td style={{ fontWeight: "400", borderBottom: "3px solid black", }}>{Item.qty}</Td>
                                    <Td style={{ fontWeight: "400", borderBottom: "3px solid black", }}>{Item.rate}</Td>
                                    <Td style={{ fontWeight: "400", borderBottom: "3px solid black", }}>{Item.amount}</Td>

                                </Tr>
                            ))
                        }
                    </Tbody>
                </Table>
            </TableContainer>


            <HStack w={'90%'} alignItems={'center'} justifyContent={'space-between'}>
                <VStack alignItems={'flex-start'}>
                    <p style={{ fontWeight: '700' }}>Sub Total</p>
                    {taxMode === 'CGST_SGST' && <p style={{ fontWeight: '700' }} isNumeric>SGST</p>}
                    {taxMode === 'CGST_SGST' && <p style={{ fontWeight: '700' }} isNumeric>CGST</p>}
                    {taxMode === 'IGST' && <p style={{ fontWeight: '700' }} isNumeric>IGST</p>}
                </VStack>

                <VStack>
                    <p >{totalAmount}</p>
                    {taxMode === 'CGST_SGST' && <p isNumeric>{totalSgstAmount}</p>}
                    {taxMode === 'CGST_SGST' && <p isNumeric>{totalCgstAmount}</p>}
                    {taxMode === 'IGST' && <p isNumeric>{totalIgstAmount}</p>}
                </VStack>
            </HStack>
            <HStack  w={'100%'}  borderTop={'3px solid black'}>

                <HStack w={'90%'} alignItems={'center'} justifyContent={'space-between'}>
                    <p style={{ fontWeight: '700' }}>Total</p>
                    <p>{totalAmountWithTax}</p>
                </HStack>
            </HStack>

            <div className="paymentMethod mt-3">
                <p>
                    <h3>Details</h3>
                    <p>{`Name : ${user?.name}`}</p>
                    <p>{`Email:${user?.email}`}</p>
                    <p>{`Phone:${user?.phone}`}</p>
                </p>

                {/* <p style={{textAlign:'center'}}>
                    <h5>{YourName}</h5>
                    <small>manager</small>
                </p> */}
            </div>
            {
                hidePrintBtn ? null : <Button children={'print'} variant={'solid'} colorScheme={"whatsapp"} onClick={() => InvoiceDataHandler()} />
            }

        </>
    )
}

export default InvoiceTemplate8