import React, { useState } from 'react'
import './InvoiceTemplate6.css'
import { MdCall } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { FaChrome } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Heading, HStack, Image, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, VStack } from '@chakra-ui/react';
import { CreateInvoice } from '../../../redux/actions/InvoiceActions';

const InvoiceTemplate6 = ({
    BillData, totalAmountWithTax, totalAmount, totalSgstAmount, totalCgstAmount, invoiceItem, invoiceLogo, totalIgstAmount, taxMode,
    invoiceName, invoiceNumber, invoiceDate, invoiceDueDate,
    CompanyName, YourName, CompanyGSTTin, CompanyCity, CompanyState, CompanyCountry,
    ClientCompanyName, ClientName, ClientGSTTin, ClientCity, ClientState, ClientCountry
}) => {
    const [hidePrintBtn, setHidePrintBtn] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { user } = useSelector(state => state.userContainer);


    const InvoiceDataHandler = async () => {
        setHidePrintBtn(true)

        await dispatch(CreateInvoice(invoiceNumber, totalAmountWithTax, invoiceDate, invoiceItem, ClientCompanyName))
        window.print()
        navigate('/profile')
    }
    return (
        <>
            <HStack w={'100%'} alignItems={'center'} justifyContent={'space-between'}>
                <VStack>
                    <HStack>

                        <Image src={invoiceLogo} alt="invoiceLogo" w={'40%'} />
                        <VStack>
                            <Text>{`${YourName}`}</Text>
                            <Text>{`${CompanyName} `}</Text>
                        </VStack>
                    </HStack>
                </VStack>

                <Heading>Invoice</Heading>
            </HStack>

            <HStack w={'100%'} alignItems={'center'} justifyContent={'space-between'}>
                <VStack alignItems={'flex-start'}>

                    <h3>{ClientCompanyName}</h3>
                    <li>{ClientName}</li>
                    <li>{`GST-Tin${ClientGSTTin} ${ClientCity} ${ClientState} ${ClientCountry}`}</li>
                </VStack>

                <VStack className="bgGreenpic">
                    <div className="bggreenText">
                        <li>number: {invoiceNumber}</li>
                        <li>date: {invoiceDate}</li>
                    </div>

                </VStack>
            </HStack>
            <TableContainer >
                <Table>
                    <Thead>

                        <Tr >
                            <Th style={{ backgroundColor: "#19bbbd", padding: '10px', color: "white", borderRadius: "20px 0 0 20px" }}>DESCRIPTION</Th>
                            <Th style={{ backgroundColor: "#19bbbd", padding: '10px', color: "white" }}> QTY</Th>
                            <Th style={{ backgroundColor: "#19bbbd", padding: '10px', color: "white" }}>PRICE</Th>
                            <Th style={{ backgroundColor: "#19bbbd", padding: '10px', color: "white", borderRadius: "0px 20px 20px 0px" }}>TOTAL</Th>
                        </Tr>


                    </Thead>
                    <Tbody>
                        {

                            BillData && BillData.map((Item, key) => (
                                <Tr key={key} >
                                    <Td style={{ color: " rgb(72, 119, 138)", padding: '10px', fontWeight: "400", textTransform: 'capitalize' }}>{Item.itemDes}</Td>
                                    <Td style={{ color: " rgb(72, 119, 138)", padding: '10px', fontWeight: "400" }}>{Item.qty}</Td>
                                    <Td style={{ color: " rgb(72, 119, 138)", padding: '10px', fontWeight: "400" }}>{Item.rate}</Td>
                                    <Td style={{ color: " rgb(72, 119, 138)", padding: '10px', fontWeight: "400" }}>{Item.amount}</Td>
                                </Tr>
                            ))
                        }
                    </Tbody>
                </Table>
            </TableContainer>

            <HStack w={'100%'} alignItems={'center'} justifyContent={'space-between'} >
                <VStack w={'40%'} alignItems={'flex-start'}>
                    <h3>notes</h3>
                    <p>Please ensure that payment is made within 30 days of receiving this invoice. A late fee of 10% per month will be applied to any outstanding balances.</p>
                </VStack>

                <VStack w={'60%'} alignItems={'flex-end'} >
                    <HStack spacing={8}>

                        <VStack alignItems={'flex-start'}>
                            <Text fontSize={'1.2rem'} fontWeight={'600'}>Sub total</Text>
                            {taxMode === 'CGST_SGST' && <Text fontSize={'1.2rem'} fontWeight={'600'} isNumeric>SGST</Text>}
                            {taxMode === 'CGST_SGST' && <Text fontSize={'1.2rem'} fontWeight={'600'} isNumeric>CGST</Text>}
                            {taxMode === 'IGST' && <Text fontSize={'1.2rem'} fontWeight={'600'} isNumeric>IGST</Text>}

                            <Text style={{ fontWeight: '600', fontSize: '1.2rem' }}>Grand Total</Text>
                        </VStack>

                        <VStack alignItems={'flex-end'}>
                            <Text style={{ fontWeight: '400', fontSize: '1.1rem' }}>{totalAmount}</Text>
                            {taxMode === 'CGST_SGST' && <Text style={{ fontWeight: '400', fontSize: '1rem' }} isNumeric>{` ${totalSgstAmount}`}</Text>}
                            {taxMode === 'CGST_SGST' && <Text style={{ fontWeight: '400', fontSize: '1rem' }} isNumeric>{` ${totalCgstAmount}`}</Text>}
                            {taxMode === 'IGST' && <Text style={{ fontWeight: '400', fontSize: '1rem' }} isNumeric>{` ${totalIgstAmount}`}</Text>}

                            <Text style={{ fontWeight: '500', fontSize: '1.3rem' }}>{totalAmountWithTax}</Text>
                        </VStack>
                    </HStack>
                </VStack>
            </HStack>

            <div className="temp-foot-clr">
                <div className="TEMP-FOOT">
                    <div className="foot1">
                        <p>{`Name : ${user?.name}`} </p>
                    </div>

                    <div className="foot2">
                        <p>{`Email : ${user?.email}`} </p>
                    </div>

                    <div className="foot3">
                        <p>{`Phone : ${user?.phone}`}</p>
                    </div>
                </div>

            </div>
            {
                hidePrintBtn ? null : <Button children={'print'} variant={'solid'} colorScheme={"whatsapp"} onClick={() => InvoiceDataHandler()} />
            }

        </>
    )
}

export default InvoiceTemplate6