import toast from 'react-hot-toast';
import { server } from '../../index';
import axios from 'axios';

export const CreateQuotation = (quotationNumber, totalAmount, quotationDate, quotationItem, ClientCompanyName) => async dispatch => { 
  try {
    dispatch({ type: 'QuotationRequest' });

    const { data } = await axios.post(
      `${server}/quotation/create`,
      { quotationNumber, totalAmount, quotationDate, quotationItem, ClientCompanyName},
      {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      }
    );

    dispatch({
      type: 'QuotationSuccess',
      payload: data
    });
    toast.success(data.message)

  } catch (error) {
    dispatch({
      type: 'QuotationFail',
      payload:error.message,
    });
    toast.error(error.message)

  }
};


export const GetAllQuotation = () => async dispatch => { 
    try {
      dispatch({ type: 'GetAllQuotationRequest' });
  
      const { data } = await axios.get(
        `${server}/quotation/getallquotationdata`,       
        {       
          withCredentials: true,
        }
      );
  
      dispatch({
        type: 'GetAllQuotationSuccess',
        payload: data
      });
    // toast.success(data.message)

    } catch (error) {
      dispatch({
        type: 'GetAllQuotationFail',
        payload:error.message,
      });
    // toast.error(error.message)

    }
 };


export const DeleteQuotation = (_id) => async dispatch => { 
    try {
      dispatch({ type: 'DeleteQuotationRequest' });
  
      const { data } = await axios.delete(
        `${server}/quotation/deletequotationdata/${_id}`,        
        {       
          withCredentials: true,
        }
      );
  
      dispatch({
        type: 'DeleteQuotationSuccess',
        payload: data
      });
    toast.success(data.message)

    } catch (error) {
      dispatch({
        type: 'DeleteQuotationFail',
        payload:error.message,
      });
    toast.error(error.message)

    }
 };

 export const AdminGetAllQuotationData = () => async dispatch => { 
  try {
    dispatch({ type: 'AdminGetAllQuotationDataRequest' });

    const { data } = await axios.get(
      `${server}/quotation/admin/get/all/quotation`,       
      {       
        withCredentials: true,
      }
    );

    dispatch({
      type: 'AdminGetAllQuotationDataSuccess',
      payload: data.bills
    });
  // toast.success(data.message)

  } catch (error) {
    dispatch({
      type: 'AdminGetAllQuotationDataFail',
      payload:error.message,
    });
  // toast.error(error.message)

  }
};

export const AdminDeleteQuotation = (_id) => async dispatch => { 
  try {
    dispatch({ type: 'AdminDeleteQuotationRequest' });

    const { data } = await axios.delete(
      `${server}/quotation/deletequotationdata/${_id}`,        
      {       
        withCredentials: true,
      }
    );

    dispatch({
      type: 'AdminDeleteQuotationSuccess',
      payload: data
    });
  toast.success(data.message)

  } catch (error) {
    dispatch({
      type: 'AdminDeleteQuotationFail',
      payload:error.message,
    });
  toast.error(error.message)

  }
};