import React, { useState } from 'react'
import './InvoiceTemplate4.css'
import logo2 from '../../../img/logo.jpeg';

import { Button, Heading, HStack, Image, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, VStack } from '@chakra-ui/react';
import { CreateInvoice } from '../../../redux/actions/InvoiceActions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const InvoiceTemplate4 = ({
    BillData, totalAmountWithTax, totalAmount, totalSgstAmount, totalCgstAmount, invoiceItem, invoiceLogo, totalIgstAmount, taxMode,
    invoiceName, invoiceNumber, invoiceDate, invoiceDueDate,
    CompanyName, YourName, CompanyGSTTin, CompanyCity, CompanyState, CompanyCountry,
    ClientCompanyName, ClientName, ClientGSTTin, ClientCity, ClientState, ClientCountry
}) => {
    const [hidePrintBtn, setHidePrintBtn] = useState(false)


    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { user } = useSelector(state => state.userContainer);


    const InvoiceDataHandler = async () => {
        setHidePrintBtn(true)

        await dispatch(CreateInvoice(invoiceNumber, totalAmountWithTax, invoiceDate, invoiceItem, ClientCompanyName))
        window.print()
        navigate('/profile')
    }

    return (
        <>

            <HStack>
                <HStack>
                    <Image src={invoiceLogo} alt="invoiceLogo" w={'30%'} />
                    <Heading fontSize={'1.2rem'} children={CompanyName} />
                </HStack>
            </HStack>

            <VStack alignItems={'flex-start'}>
                <Heading fontSize={'1.4rem'} >Invoice to: {ClientCompanyName}</Heading>
                <Text textTransform={'uppercase'} children={ClientName} />
            </VStack>

            <HStack w={'100%'} alignItems={'center'} justifyContent={'space-between'}>
                <VStack alignItems={'flex-start'} w={'60%'}>
                    <Text fontWeight={'600'}>Phone : <span style={{ fontWeight: '400' }}>123-456-789</span> </Text>
                    <Text fontWeight={'600'}>Address : <span style={{ fontWeight: '400' }}>{`${ClientCity} ${ClientState} ${ClientCountry}`}</span></Text>
                </VStack>
                <VStack alignItems={'flex-end'} w={'40%'}>
                    <Text fontWeight={'600'}>invoice date:</Text>
                    <Text><span style={{ fontWeight: '400' }}>{invoiceDate}</span></Text>
                </VStack>
            </HStack>

            <TableContainer>
                <Table colorScheme='teal'>
                    <Thead>
                        <Tr>
                            <Th bg={'teal'} color={'white'} fontSize={'1.2rem'}>DESCRIPTION</Th>
                            <Th bg={'teal'} color={'white'} fontSize={'1.2rem'}> QTY</Th>
                            <Th bg={'teal'} color={'white'} fontSize={'1.2rem'}>TOTAL</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {

                            BillData && BillData.map((Item, key) => (
                                <Tr key={key}>
                                    <Td style={{ color: "rgb(6, 6, 83)", fontWeight: "700", textTransform: 'capitalize' }}>{Item.itemDes}</Td>
                                    <Td style={{ color: "rgb(6, 6, 83)", fontWeight: "700" }}>{Item.qty}</Td>
                                    <Td style={{ color: "rgb(6, 6, 83)", fontWeight: "700" }}>{Item.amount}</Td>
                                </Tr>
                            ))
                        }
                    </Tbody>
                </Table>
            </TableContainer>

            <HStack mt={5} w={'100%'} justifyContent={'space-between'}>
                <VStack w={'50%'} alignItems={'flex-start'}>
                    <Text fontSize={'1.2rem'} fontWeight={'600'}>terms & conditions </Text>
                    <Text>Please send payment within 30 days of receiving this invoice. There will be 10% interest charge per month on late invoice.</Text>
                </VStack>

                <VStack w={'50%'} alignItems={'flex-end'}>
                    <HStack w={'100%'} alignItems={'flex-end'} justifyContent={'flex-end'}>
                        <VStack alignSelf={'flex-start'} >
                            <Text  fontWeight={'700'} fontSize={'1.2rem'} width={'100px'}>Sub-total:</Text>
                            {taxMode === 'CGST_SGST' && <Text  fontWeight={'700'} fontSize={'1.2rem'} width={'100px'}>SGST:</Text>}
                            {taxMode === 'CGST_SGST' && <Text  fontWeight={'700'} fontSize={'1.2rem'} width={'100px'}>CGST:</Text>}
                            {taxMode === 'IGST' && <Text  fontWeight={'700'} fontSize={'1.2rem'} width={'100px'}>IGST:</Text>}
                            <Text  fontWeight={'700'} fontSize={'1.2rem'} width={'100px'}>Total:</Text>
                        </VStack>

                        <VStack alignItems={'flex-end'} >
                            <Text  width={'100px'} fontWeight={'700'} fontSize={'1.2rem'} textAlign={'right'}>{` ${totalAmount}`}</Text>
                            {taxMode === 'CGST_SGST' && <Text fontWeight={'700'} fontSize={'1.2rem'} width={'100px'} textAlign={'right'}>{` ${totalSgstAmount}`}</Text>}
                            {taxMode === 'CGST_SGST' && <Text  fontWeight={'700'} fontSize={'1.2rem'} width={'100px'} textAlign={'right'}>{` ${totalCgstAmount}`}</Text>}
                            {taxMode === 'IGST' && <Text  fontWeight={'700'} fontSize={'1.2rem'} width={'100px'} textAlign={'right'}>{` ${totalIgstAmount}`}</Text>}
                            <Text  width={'100px'} fontWeight={'700'} fontSize={'1.2rem'} textAlign={'right'}>{` ${totalAmountWithTax}`}</Text>
                        </VStack>
                    </HStack>
                </VStack>

            </HStack>

            <HStack w={'100%'} alignItems={'center'} justifyContent={'space-between'}>
                <VStack w={'60%'} alignItems={'flex-start'}>

                    <Text fontWeight={'700'} fontSize={'1.2rem'}>Phone number: {user?.phone}</Text>
                    <Text fontWeight={'700'} fontSize={'1.2rem'}>Email address: {user?.email}</Text>


                </VStack>

                <VStack w={'40%'} alignItems={'flex-end'}>
                    <h3>{YourName}</h3>
                    <Text borderTop={'1px solid teal'}>administrator</Text>
                </VStack>
            </HStack>
            {
                hidePrintBtn ? null : <Button children={'print'} variant={'solid'} colorScheme={"whatsapp"} onClick={() => InvoiceDataHandler()} />
            }

        </>
    )
}

export default InvoiceTemplate4